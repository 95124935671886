import React, { Component } from 'react'
import './App.css'
import Header from './Header.js'
import Lightning from './Lightning.js'
import Body from './Body.js'
import sunIcon from './images/sunny.gif'
import partCloudIcon from './images/partcloud.gif'
import cloudIcon from './images/cloud.gif'
import rainIcon from './images/raincloud.gif'
import snowIcon from './images/snowcloud.gif'
import windIcon from './images/wind.gif'
import lightningIcon from './images/lightning.gif'
import rotateRequest from './images/rotationrequest.svg'
import rotateFifteen from './images/rotation15.svg'
import rotateTwenty from './images/rotation20.svg'
import rotateTen from './images/rotation10.svg'
import rotateThirty from './images/rotation30.svg'
import rotateNot from './images/rotationnone.svg'
// import umbrella from './images/umbrella.png'
// import umbrellaDown from './images/umbrelladown.png'
// import twoLanes from './images/twolane.png'
// import oneLane from './images/onelane.png'
// import noLanes from './images/nolane.png'
import peabodyOutside from './images/peabodyoutside.png'
import peabodyInside from './images/peabodyinside.png'
// const apiKey = process.env.REACT_APP_COW_API_KEY
const accountSid = "ACd6a04526ba4225f5bd8a7e38339a47d5";
const authToken = "9a3412aa6b40ffe28af21ca2b1b9a361";
// const client = require('twilio')(accountSid, authToken);
const axios = require("axios");
const qs = require('qs');

class App extends Component {
  constructor() {
    super()

    this.state = {
      weatherData: {},
      dateArray: [],
      updated: false,
      shouldCowGo: true,
      condition: 1,
      currentHour: 1,
      futureHour: 0,
      rotation: rotateRequest,
      upcomingHours: [],
      currentWeather: {},
      textUpdate: false,
      textSent: false,
      today: new Date(),
      reason: false,
      peabodyArray: [peabodyOutside, peabodyOutside, peabodyOutside, peabodyOutside, peabodyInside, peabodyInside],
      outsideArray: ["Outside", "Outside", "Outside", "Outside", "Inside", "Inside"],
      rotationArray: [rotateRequest, rotateThirty, rotateTwenty, rotateFifteen, rotateTen, rotateNot],
      notesArray: ["", "", "", "", "No OTs or Expo, 1 Payment on 10 Minute Rotation, 3 Payment People", "Too Cold!"]
    }

    this.cowText = this.cowText.bind(this)
    this.cowTextYes = this.cowTextYes.bind(this)
    this.cowTextNo = this.cowTextNo.bind(this)
    this.analyzeHourlyWeather = this.analyzeHourlyWeather.bind(this)
    this.getWeatherData = this.getWeatherData.bind(this)
    this.useHourlyWeatherData = this.useHourlyWeatherData.bind(this)
    this.analyzeDailyWeather = this.analyzeDailyWeather.bind(this)
    this.evaluateCurrentWeather = this.evaluateCurrentWeather.bind(this)
    this.checkWeather = this.checkWeather.bind(this)
    this.getCurrentHourWeather = this.getCurrentHourWeather.bind(this)
    this.isPeabodyOutside = this.isPeabodyOutside.bind(this)
    this.showFutureWeather = this.showFutureWeather.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
  }

  componentDidMount() {
    this.getWeatherData()
    // this.checkTextDates()
    setTimeout(() => window.location.reload(), 300000)
  }

  componentDidUpdate() {
    if(this.state.updated === false && this.state.textUpdate === false) {
      this.analyzeHourlyWeather(this.state.weatherData)
      this.analyzeDailyWeather(this.state.weatherData)
      this.setState({updated: true, currentHour: this.state.today.getHours()})
    }
    if(this.state.updated === true && this.state.textUpdate === false) {
      setTimeout(() => {
        const dateArray = this.state.dateArray.map(date => {
          const newDate = new Date(date);
          return newDate.getDay();
        })
        this.setState({dateArray: dateArray, textUpdate: true});
      }, 250)
    }
    if(this.state.textUpdate === true && this.state.textSent === false) {
      setTimeout(() => {
        const today = this.state.today.getDay();
        if (!this.state.dateArray.includes(today) && this.state.currentHour === 7 && this.state.today.getMinutes() >= 30 && this.state.today.getMinutes() <= 45) {
          this.cowText()
        }
        this.setState({textSent: true})
      }, 250)
    }
  }

  cowText() {
    if (this.state.shouldCowGo) {
      this.cowTextYes()
    } else {
      this.cowTextNo()
    }
  }

  async cowTextYes() {
    let today = new Date(Date.now())
    today = today.toDateString()
    await(axios.post("https://api.twilio.com/2010-04-01/Accounts/" + accountSid + "/Messages.json", qs.stringify({
      Body: "You should put up the cow today, " + today + "." ,
      From: '+19706506021',
      To: '+19785478646'
    }), {
      auth: {
        username: accountSid,
        password: authToken
      }
    }));
  }

  async cowTextNo() {
    let today = new Date(Date.now())
    today = today.toDateString()
    await(axios.post("https://api.twilio.com/2010-04-01/Accounts/" + accountSid + "/Messages.json", qs.stringify({
      Body: "Do *NOT* put up the cow today, " + today + ". The reason is: " + this.state.reason + ".",
      From: '+19706506021',
      To: '+19785478646'
    }), {
      auth: {
        username: accountSid,
        password: authToken
      }
    }));
  }



  async getWeatherData() {
    return fetch(`https://api.openweathermap.org/data/3.0/onecall?lat=39.0237&lon=-104.8176&exclude=current,minutely,daily,alerts&units=imperial&appid=6f606f13669752cd4c3cd8d4b6a3516a`)
      .then(data => data.json())
      .then(weatherData => this.setState({weatherData: weatherData, currentWeather: {feelsLike: weatherData.hourly[0]["feels_like"], weather: weatherData.hourly[0].weather[0].main, description: weatherData.hourly[0].weather[0].description, icon: this.checkWeather(weatherData.hourly[0])}, upcomingHours: weatherData.hourly}))
      .catch(error => console.log(error))
  }

  useHourlyWeatherData(weatherData, hour, date) {
    return weatherData.hourly?.slice(0, hour - date.getHours())
  }

  analyzeHourlyWeather(weatherData) {
    const today = this.state.today
    if(today.getHours() < 20) {
      const necessaryHours = this.useHourlyWeatherData(weatherData, 20, today)
      const general = necessaryHours.map(hour => {
        return hour.weather[0].main
      })
      const windSpeed = necessaryHours.map(hour => {
        return hour["wind_speed"]
      })
      if (general.includes("Rain") || general.includes("Snow") || windSpeed >= 6) {
        this.setState({shouldCowGo: false})
        if (general.includes("Rain")) {
          this.setState({reason: "Rain 🌧"})
        } else if (general.includes("Snow")) {
          this.setState({reason: "Snow 🌨"})
        } else if (windSpeed >= 6) {
          this.setState({reason: "Too Windy 🌬"})
        }
      }
    }
  }

  evaluateCurrentWeather(currentWeatherData) {
    const feelsLikeTemp = this.state.futureHour !== 0 ? currentWeatherData[this.state.futureHour]["feels_like"] : currentWeatherData[0]["feels_like"]
    if (feelsLikeTemp <= 0) {
      this.setState({condition: 6})
    } else if (feelsLikeTemp <= 11) {
      this.setState({condition: 5})
    } else if (feelsLikeTemp <= 25) {
      this.setState({condition: 4})
    } else if (feelsLikeTemp <= 35) {
      this.setState({condition: 3})
    } else if (feelsLikeTemp <= 40) {
      this.setState({condition: 2})
    }
  }

  analyzeDailyWeather(weatherData) {
    const hourlyWeather = this.state.upcomingHours.map(hour => {
      return {feelsLike: hour["feels_like"], weather: hour.weather[0].main, description: hour.weather[0].description, icon: this.checkWeather(hour)}
    })
    this.setState({upcomingHours: hourlyWeather})
    this.evaluateCurrentWeather(weatherData.hourly)
  }

  checkWeather(hour) {
    if (hour.weather === "Clear" || hour.weather[0].main === "Clear") {
      return sunIcon;
    } if ((hour.weather === "Clouds" || hour.weather[0].main === "Clouds") && ((hour.weather[0].description === "few clouds" || hour.weather[0].description === "scattered clouds") || (hour.description === "few clouds" || hour.description === "scattered clouds"))) {
      return partCloudIcon;
    } else if (hour.weather === "Clouds" || hour.weather[0].main === "Clouds") {
      return cloudIcon;
    } if (hour.weather === "Rain" || hour.weather[0].main === "Rain" || hour.weather === "Thunderstorm" || hour.weather[0].main === "Thunderstorm" || hour.weather === "Drizzle" || hour.weather[0].main === "Drizzle") {
      return rainIcon;
    } if (hour.weather === "Thunderstorm" || hour.weather[0].main === "Thunderstorm") {
      return lightningIcon;
    } if (hour.weather === "Snow" || hour.weather[0].main === "Snow") {
      return snowIcon;
    } if (hour.weather === "Wind" || hour.weather[0].main === "Wind") {
      return windIcon;
    }
  }

  getCurrentHourWeather() {
    const icon = this.checkWeather(this.state.upcomingHours[0])
    return icon
  }

  menuHandler() {
    const popout = document.querySelector(".popout")
    if (popout.style.display === "") {
      popout.style.display = "block"
    } else if (popout.style.display === "block") {
      popout.style.display = ""
    }
  }

  hideMenu() {
    const popout = document.querySelector(".popout")
    popout.style.display = ""
  }

  isPeabodyOutside() {
    if ((this.state.condition - 1) < 3) {
      return "Outside"
    } else {
      return "Inside"
    }}

  showFutureWeather(event) {
    this.setState({currentWeather: this.state.upcomingHours[event.target.closest("button").name], futureHour: parseInt(event.target.closest("button").name) + parseInt(this.state.currentHour)});
    this.menuHandler();
  }

  render() {
    const usableHour = this.state.futureHour > 0 ? this.state.futureHour : this.state.currentHour
    const canGoOutside = (parseInt(this.state.currentWeather.feelsLike)  > 0 || !this.state.currentWeather.weather?.includes("hunderstorm"))
    return (
      <div className="App">
        <Header feelsLike={this.state.currentWeather.feelsLike} menuHandler={this.menuHandler}/>
        <section className="popout">
          {this.state.upcomingHours.map((hour, index) => {
            const weather = this.checkWeather(hour)
            if (index < (20 - this.state.currentHour)) {
              return <button className="future" name={index} key={index} onClick={this.showFutureWeather}>
                        {(this.state.currentHour + index <= 12) ? this.state.currentHour + index : this.state.currentHour + index - 12} Should Feel Like: {parseInt(hour.feelsLike)} <img src={weather} width={"45vw"} alt="The weather for the upcoming hours"/>
                      </button>
            } else return undefined;
          })}
        </section>
        <section className="today" onClick={this.hideMenu}>
          {canGoOutside && (
            <section className="upperSection">
              <h2 className="feelsLike">{usableHour > 12 ? usableHour - 12 : usableHour} Feels Like: {parseInt(this.state.currentWeather.feelsLike)}</h2>
              <h2 className="marginContainer"><img className="feelsLikeIcon" src={this.state.currentWeather.icon} alt="The current weather"/></h2>
            </section>
          )}
          {canGoOutside && (
            <Body condition={this.state.condition} outsideArray={this.state.outsideArray} peabodyArray={this.state.peabodyArray} rotationArray={this.state.rotationArray} notesArray={this.state.notesArray} />
          ) }
          {!canGoOutside && (
            <Lightning />
          ) }
          {parseInt(this.state.currentWeather["feels_like"]) <= 0 && (
            <h3 className="notes">Additional Notes: {this.state.notesArray[this.state.condition - 1]} </h3>
          )}
        </section>
      </div>
    );
  }
}

export default App;

//  async checkTextDates() {
  //   fetch("https://fierce-coast-22444.herokuapp.com/api/")
  //     .then(response => response.json())
  //     .then(dataArray => dataArray.map(date => this.state.dateArray.push(date)))
  //     .catch(error => console.log(error))
  // }
  //
  // async postTextDate() {
  //   fetch("https://fierce-coast-22444.herokuapp.com/api/", {
  //   method: 'POST',
  //   headers: {
  //     'content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({date: Date.now()})
  // })
  //     .then(response => response.json())
  //     .catch(error => console.log(error))
  // }


//purple rgba(109,0,255,1)
//yellow rgba(255,211,0,1)
//light blue rgba(70,194,252,1)
//dark blue rgba(0,46,255,1)
